{
  "ABOUT_TITLE": "Welcome to Donutizer - Employee Appreciation App!",
  "ABOUT_DESC": "Join our app and start building a culture of appreciation in your company today! Let every employee feel valued and supported on our shared journey to success.",
  "HEAD_TITLE": "Donutize - an app by and for employees",
  "SPAN_LOADING": "Loading...",
  "H1_TITLE": "Donutize",
  "NAV_HOME": "Donut",
  "NAV_ABOUT": "About Donutize",
  "NAV_FEATURE": "Features",
  "NAV_COMPANIES": "Companies",
  "NAV_PRICING": "Pricing",
  "NAV_CONTACT": "Contact",
  "BTN_LOGIN": "Log in",
  "BTN_SUBMIT": "Submit",
  "BTN_TRY": "Try",
  "BTN_TRY_FOR_FREE": "Try for free",
  "BTN_LEARN_MORE": "Learn more",
  "BTN_CONTACT_US": "Contact us",
  "ABOUT_H5": "About Donutize",
  "ABOUT_H1": "#1 Among Apps for Building Good Relationships in the Company",
  "ABOUT_P": "Every employee plays a crucial role in the success of the company. That's why we provide you with a tool that allows expressing gratitude and appreciation for the effort and dedication of employees at every stage of their work. Our app is a platform that enables mutual recognition and support among team members. Whether you want to praise for a job well done, thank for help in a challenging task, or recognize for excellent ideas, our app provides tools to build a positive work culture. Why consider our solution:",
  "ABOUT_LI1": "Convenient and easy to use: Our app is intuitive and user-friendly, allowing quick and effective employee appreciation.",
  "ABOUT_LI2": "Building relationships: Appreciating colleagues is not just a matter of recognition but also building positive interpersonal relationships.",
  "ABOUT_LI3": "Motivation and engagement: Recognition for good work and effort increases employee motivation and engagement.",
  "ABOUT_LI4": "Customized to company needs: Our app can be tailored to the specific needs of your company, allowing personalized forms of appreciation.",
  "FEATURES_H5": "Cool Features",
  "FEATURES_H1": "What Sets Us Apart",
  "COMPANIES_H1": "Clients",
  "FEATURES_SIMPLICITY_H5": "Simplicity",
  "FEATURES_SIMPLICITY_P": "Setting up a reward round is just a few clicks, and voting is just two clicks away.",
  "FEATURES_GAMIFICATION_H5": "Positive Gamification",
  "FEATURES_GAMIFICATION_P": "Applied gamification model influences increased motivation and engagement of employees.",
  "FEATURES_ON_DEMAND_H5": "Data Always at Your Fingertips",
  "FEATURES_ON_DEMAND_P": "On specially prepared dashboards, you have constant access to current data, including how many employees have already cast their votes.",
  "FEATURES_CONVENIENT_WORK_H5": "Convenient Work",
  "FEATURES_CONVENIENT_WORK_P": "Use the app as you like: on desktop, tablet, phone. The app looks great and works the same on all devices.",
  "FEATURES_CLEAR_SUBSCRIPTIONS_H5": "Transparent Subscription Model",
  "FEATURES_CLEAR_SUBSCRIPTIONS_P": "Use it whenever and however you want. We don't tie you with any contracts.",
  "FEATURES_STAY_UPDATED_H5": "Stay Updated",
  "FEATURES_STAY_UPDATED_P": "We ensure continuous system development. New features are introduced and made available at no extra cost.",
  "PROCESS_H5": "How to Get Started",
  "PROCESS_H1": "In 3 Simple Steps",
  "PROCESS_REGISTER_H5": "Register Your Organization",
  "PROCESS_REGISTER_P": "Register your profile and enjoy a 30-day trial period.",
  "PROCESS_CONFIGURE_H5": "Configure the Game",
  "PROCESS_CONFIGURE_P": "Add users, configure the game: establish rules and rewards.",
  "PROCESS_OBSERVE_H5": "Observe Positive Effects",
  "PROCESS_OBSERVE_P": "See how the engagement of your employees and motivation to help others grow.",
  "PRICING_H5": "Pricing",
  "PRICING_H1": "Choose Your Plan",
  "PRICING_SWITCH_MONTHLY": "Monthly",
  "PRICING_SWITCH_YEARLY": "Yearly",
  "PRICING_PRICE": "{price} PLN",
  "PRICING_PER_MONTH": "/month*",
  "PRICING_PER_YEAR": "/year*",
  "PRICING_USERS_AMOUNT": "up to {amount} users",
  "PRICING_DIET_H4": "Diet Donut",
  "PRICING_DIET_SPAN": "For companies with up to 50 employees",
  "PRICING_DIET_ADVANTAGE1": "individual round play",
  "PRICING_DIET_ADVANTAGE2": "team round play",
  "PRICING_DIET_ADVANTAGE3": "access to the dashboard",
  "PRICING_JAM_H4": "Jam Donut",
  "PRICING_JAM_SPAN": "For companies with 51 to 100 employees",
  "PRICING_JAM_ADVANTAGE1": "individual round play",
  "PRICING_JAM_ADVANTAGE2": "team round play",
  "PRICING_JAM_ADVANTAGE3": "access to the dashboard",
  "PRICING_CREAM_H4": "Cream Donut",
  "PRICING_CREAM_SPAN": "For companies with 101 to 200 employees",
  "PRICING_CREAM_ADVANTAGE1": "individual round play",
  "PRICING_CREAM_ADVANTAGE2": "team round play",
  "PRICING_CREAM_ADVANTAGE3": "access to the dashboard",
  "PRICING_BOTTOM_P1": "*Prices do not include VAT",
  "PRICING_BOTTOM_P2": "Didn't find a plan for yourself? Have a larger company? Don't worry - contact us, and we'll prepare a special offer for you.",
  "CONTACT_H5": "Contact",
  "CONTACT_H1": "Write to Us",
  "CONTACT_P": "If you have any questions, write to us. We usually respond within 24 hours.",
  "CONTACT_INPUT_NAME": "Name and Surname",
  "CONTACT_INPUT_EMAIL": "Email",
  "CONTACT_INPUT_TITLE": "Title",
  "CONTACT_INPUT_MESSAGE": "Message",
  "FOOTER_ADDRESS_H4": "Address",
  "FOOTER_SHORTCUTS_H4": "Shortcuts",
  "FOOTER_SHORTCUTS_ABOUT": "About the App",
  "FOOTER_SHORTCUTS_CONTACT": "Contact Us",
  "FOOTER_SHORTCUTS_PRIVACY": "Privacy Policy",
  "FOOTER_SHORTCUTS_RULES": "Terms and Conditions",
  "FOOTER_SHORTCUTS_PRICING": "Pricing Plans"
}
