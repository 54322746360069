{
  "ABOUT_TITLE": "Witaj w Donutizer - Aplikacji Doceniającej Pracowników!",
  "ABOUT_DESC": "Dołącz do naszej aplikacji i zacznij budować kulturę doceniania w swojej firmie już dziś! Niech każdy pracownik poczuje się doceniony i wsparciem w naszej wspólnej drodze do sukcesu.",
  "HEAD_TITLE": "Donutize - aplikacja od i dla pracowników",
  "SPAN_LOADING": "Ładowanie...",
  "H1_TITLE": "Donutize",
  "NAV_HOME": "Donut",
  "NAV_ABOUT": "O Donutize",
  "NAV_FEATURE": "Rozwiązanie",
  "NAV_PRICING": "Cennik",
  "NAV_CONTACT": "Kontakt",
  "NAV_COMPANIES": "Wybrali nas",
  "BTN_LOGIN": "Zaloguj",
  "BTN_SUBMIT": "Wyślij",
  "BTN_TRY": "Wypróbuj",
  "BTN_TRY_FOR_FREE": "Wypróbuj za darmo",
  "BTN_LEARN_MORE": "Dowiedz się więcej",
  "BTN_CONTACT_US": "Skontaktuj się z nami",
  "ABOUT_H5": "O Donutize",
  "ABOUT_H1": "#1 Wsród aplikacji do budowania dobrych relacji w firmie",
  "ABOUT_P": "Każdy pracownik odgrywa kluczową rolę w sukcesie firmy. Dlatego też pragniemy dostarczamy Ci narzędzie, które umożliwi wyrażenie wdzięczności i docenienie za wysiłek oraz zaangażowanie pracowników na każdym etapie ich pracy. Nasza aplikacja to platforma, która umożliwia wzajemne wyrażanie uznania i wsparcia między członkami zespołu. Niezależnie od tego, czy chcesz pochwalić za wykonaną pracę, podziękować za pomoc w trudnym zadaniu czy wyróżnić za doskonałe pomysły, nasza aplikacja zapewnia narzędzia do budowania pozytywnej kultury pracy. Dlaczego warto zainteresować się naszym rozwiązaniem:",
  "ABOUT_LI1": "Wygodne i proste w użyciu: Nasza aplikacja jest intuicyjna i łatwa w obsłudze, co umożliwia szybkie i efektywne docenianie pracowników.",
  "ABOUT_LI2": "Wzmacnianie relacji: Docenianie kolegów pracy to nie tylko kwestia uznania, ale także budowania pozytywnych relacji międzyludzkich.",
  "ABOUT_LI3": "Motywacja i zaangażowanie: Uznanie za dobrą pracę i wysiłek zwiększa motywację i zaangażowanie pracowników.",
  "ABOUT_LI4": "Dostosowane do potrzeb firmy: Nasza aplikacja może być dostosowana do specyfiki Twojej firmy, umożliwiając spersonalizowane formy docenienia.",
  "FEATURES_H5": "Co fajnego",
  "FEATURES_H1": "Nas wyróżnia",
  "COMPANIES_H1": "Wybrali nas",
  "FEATURES_SIMPLICITY_H5": "Prostota",
  "FEATURES_SIMPLICITY_P": "Konfiguracja rundy z nagrodami to tylko kilka kliknięć, a oddanie głosu to zaledwie dwa kliknięcia",
  "FEATURES_GAMIFICATION_H5": "Pozytywna grywalizacja",
  "FEATURES_GAMIFICATION_P": "Zastosowany model grywalizacji wpływa na ziększenie motywacji i zaangazowania pracowników.",
  "FEATURES_ON_DEMAND_H5": "Dane zawsze pod ręką",
  "FEATURES_ON_DEMAND_P": "Na specjalnie przygotowanych dashbordach masz stały dostęp do aktualnych danych m.in. wiesz ilu pracowników oddało już swój głos.",
  "FEATURES_CONVENIENT_WORK_H5": "Wygodna praca",
  "FEATURES_CONVENIENT_WORK_P": "Korzystaj z aplikacji tak jak lubisz: na desktopie, tablecie, telefonie. Aplikacja na wszystkich urzadzeniach wygląda świetnie i tak samo działa.",
  "FEATURES_CLEAR_SUBSCRIPTIONS_H5": "Przejrzysty model subskrypcji",
  "FEATURES_CLEAR_SUBSCRIPTIONS_P": "Korzystaj kiedy chcesz i ile chcesz. Nie wiążemy Cię żadną umową.",
  "FEATURES_STAY_UPDATED_H5": "Bądź na bieżąco",
  "FEATURES_STAY_UPDATED_P": "Zapewniamy stały rozwój systemu. Nowe funkcjonalności są wprowadzane i udostepniane bez dodatkowych opłat.",
  "PROCESS_H5": "Jak zacząć",
  "PROCESS_H1": "W 3 prostych krokach",
  "PROCESS_REGISTER_H5": "Zarejestruj swoją organizację",
  "PROCESS_REGISTER_P": "Zarejestruj swój profil i ciesz się 30 dniowym okresem próbnym.",
  "PROCESS_CONFIGURE_H5": "Skonfiguruj grę",
  "PROCESS_CONFIGURE_P": "Dodaj użytkowników, skonfiguruj grę: ustal zasady i nagrody.",
  "PROCESS_OBSERVE_H5": "Obserwuj pozytywne efekty",
  "PROCESS_OBSERVE_P": "Zobacz jak wzrasta zaangażowanie Twoich pracowników i motywacja do pomagania innym.",
  "PRICING_H5": "Cennik",
  "PRICING_H1": "Wybierz swój plan",
  "PRICING_SWITCH_MONTHLY": "Miesięczny",
  "PRICING_SWITCH_YEARLY": "Roczny",
  "PRICING_PRICE": "{price}zł",
  "PRICING_PER_MONTH": "/miesiąc*",
  "PRICING_PER_YEAR": "/rok*",
  "PRICING_USERS_AMOUNT": "do {amount} użytkowników",
  "PRICING_DIET_H4": "Pączek dietetyczny",
  "PRICING_DIET_SPAN": "Dla firm do 50 pracowników",
  "PRICING_DIET_ADVANTAGE1": "rozgrywanie rund indywidualnych",
  "PRICING_DIET_ADVANTAGE2": "rozgrywanie rund drużynowych",
  "PRICING_DIET_ADVANTAGE3": "dostęp do dashboardu",
  "PRICING_JAM_H4": "Pączek z dżemem",
  "PRICING_JAM_SPAN": "Dla firm od 51 do 100 pracowników",
  "PRICING_JAM_ADVANTAGE1": "rozgrywanie rund indywidualnych",
  "PRICING_JAM_ADVANTAGE2": "rozgrywanie rund drużynowych",
  "PRICING_JAM_ADVANTAGE3": "dostęp do dashboardu",
  "PRICING_CREAM_H4": "Pączek z kremem",
  "PRICING_CREAM_SPAN": "Dla firm od 101 do 200 pracowników",
  "PRICING_CREAM_ADVANTAGE1": "rozgrywanie rund indywidualnych",
  "PRICING_CREAM_ADVANTAGE2": "rozgrywanie rund drużynowych",
  "PRICING_CREAM_ADVANTAGE3": "dostęp do dashboardu",
  "PRICING_BOTTOM_P1": "*Ceny nie zawierają VAT",
  "PRICING_BOTTOM_P2": "Nie znalazłeś planu dla siebie? Masz większą firmę? Nic straconego - skontaktuj się z nami a przygotujemy dla Ciebie specjalną ofertę.",
  "CONTACT_H5": "Kontakt",
  "CONTACT_H1": "Napisz do nas",
  "CONTACT_P": "Jeżeli masz jakiekolwiek pytania to napisz do nas. Odpowiadamy zazwyczaj w ciągu 24h.",
  "CONTACT_INPUT_NAME": "Imię i Nazwisko",
  "CONTACT_INPUT_EMAIL": "Email",
  "CONTACT_INPUT_TITLE": "Tytuł",
  "CONTACT_INPUT_MESSAGE": "Wiadomość",
  "FOOTER_ADDRESS_H4": "Adres",
  "FOOTER_SHORTCUTS_H4": "Skróty",
  "FOOTER_SHORTCUTS_ABOUT": "O aplikacji",
  "FOOTER_SHORTCUTS_CONTACT": "Skontaktuj się z nami",
  "FOOTER_SHORTCUTS_PRIVACY": "Polityka prywatności",
  "FOOTER_SHORTCUTS_RULES": "Regulamin",
  "FOOTER_SHORTCUTS_PRICING": "Plany cenowe"
}
